
.starratingscss {
    unicode-bidi: bidi-override;
    color: #c5c5c5;
    /* font-size: 25px; */
    height: 25px;
    /* width: 100px; */
    position: relative;
    /* text-shadow: 0px 1px 0 #a2a2a2; */
  }
  .starratingscsstop {
    color: rgb(216, 0, 0);
    padding: 0;
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .starratingscssbottom {
    padding: 0;
    display: block;
    z-index: 0;
  }

  button {
      margin:10px 0;
  }

 .comments:last-child hr {
    display:none;
}