.gears {
  width: 350px;
  margin: 100px auto;
  display: block;
  text-align: center;
  font-weight: bold;
  font-family: Arial;
  color: #ef4657;
  text-shadow: 1px 1px 2px #ee838e
}

.gears .big {
  width: 100px;
  height: 100px;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
  -webkit-animation: rotate 1.5s linear infinite;
  -moz-animation: rotate 1.5s linear infinite;
  -o-animation: rotate 1.5s linear infinite;
  animation: rotate 1.5s linear infinite
}

.gears .small {
  width: 70px;
  height: 70px;
  margin-left: -6px;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
  -webkit-animation: reverseRotate 1.5s linear infinite;
  -moz-animation: reverseRotate 1.5s linear infinite;
  -o-animation: reverseRotate 1.5s linear infinite;
  animation: reverseRotate 1.5s linear infinite;
}


@-webkit-keyframes rotate {
  0% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
  100% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
}


@-moz-keyframes rotate {
  0% {
     -moz-transform: rotate(0);
     transform: rotate(0);
  }
  100% {
     -moz-transform: rotate(180deg);
     transform: rotate(180deg);
  }
}


@-o-keyframes rotate {
  0% {
     -o-transform: rotate(0);
     transform: rotate(0);
  }
  100% {
     -o-transform: rotate(180deg);
     transform: rotate(180deg);
  }
}


@keyframes rotate {
  0% {
     -webkit-transform: rotate(0);
     -moz-transform: rotate(0);
     -o-transform: rotate(0);
     transform: rotate(0);
  }
  100% {
     -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
     -o-transform: rotate(180deg);
     transform: rotate(180deg);
  }
}

@-webkit-keyframes reverseRotate {
  0% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
  100% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
}

@-moz-keyframes reverseRotate {
  0% {
     -moz-transform: rotate(180deg);
     transform: rotate(180deg);
  }
  100% {
     -moz-transform: rotate(0);
     transform: rotate(0);
  }
}

@-o-keyframes reverseRotate {
  0% {
     -o-transform: rotate(180deg);
     transform: rotate(180deg);
  }
  100% {
     -o-transform: rotate(0);
     transform: rotate(0);
  }
}

@keyframes reverseRotate {
  0% {
     -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
     -o-transform: rotate(180deg);
     transform: rotate(180deg);
  }
  100% {
     -webkit-transform: rotate(0);
     -moz-transform: rotate(0);
     -o-transform: rotate(0);
     transform: rotate(0);
  }
}
