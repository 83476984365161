.nav-item {
  line-height: 36px;
  /* border-bottom: 2px solid transparent; */
}

/* 
.nav-item:hover {
  border-bottom: #EF4657 solid 2px;   
} */

.nav-link {
    color: #292929 !important;
    font-size: 12px; 

}


.nav-link:hover , .nav-link:focus {
    color: #EF4657 !important;
   
}

.mainmenu-link {
  border: 1px solid #292929;
  border-radius: 4px;
}
