.footer {

   background: #292929;
   color: #fff;
   padding: 0 20px;
   min-height: 100px;

}

.footer  .row {
 padding: 0 20px;
}

.footerLogo {

display: flex;
color: #fff;
height: 66px;
font-size: 24px;
padding:  20px 0;

}

.footer  h5 {
font-size: 0.85rem;
color: #b3b3b3;
margin-top: 10px;
text-transform: uppercase;
}

.footer a, .footer p {
color: white;
font-size: 0.85rem;
}

.footer  a:hover {
color: #b3b3b3;
}


.footer  li {
list-style: none;
margin: 5px 0;
}

.socialIcons a{
color: #fff;
margin:4px;
height: 40px;
font-size: 2rem; }

