

  :root {
    --main-color: #ef4657  ;
  }

  main {
   /* padding-top : 20px;
   text-align: center; */
   min-height: 100vh;
  
  }
  #header {
    background:   linear-gradient(
      rgba(0, 0, 0, 0.7), 
      rgba(0, 0, 0, 0.7)
    ),url("/img/partner_bg_1.jpg");
    background-size: cover;
    /* background-position: top; */
    min-height: 500px;
    padding-top: 100px;
    padding-bottom: 100px;
       }

#header h1 {
    color: white;
}
    
  
  #rest {
    background:   linear-gradient(
      rgba(0, 0, 0, 0.7), 
      rgba(0, 0, 0, 0.7)
    ),url("/img/partner_bg_1.jpg");
    background-size: cover;
    /* background-position: top; */
    min-height: 100vh; 
    padding-top: 100px;
    }
    
  
  .signup {
    background-color: #fcfcfc;
    border-radius: 4px;
    padding: 20px;
  }
  
  .signup h4 {
    margin-bottom: 20px;
  }
  
  .signup a {
    color: #999;
    text-decoration: none;
  }
  
  .signup a:hover {
    color: black;
  }
  
  .intro {
    color: #e0e0e0;
    text-shadow: 0 0 3px rgb(0, 0, 0), 0 0 5px rgb(0, 0, 0);
  }
  
  
  /* .intro h1 {
    padding-top: 20px;
  } */
  
  .intro h4 {
    margin-top: 45px;
    line-height: 35px;
  }


  .main {
    min-height: 80vh;
}

/*********marketing**************/

.how {
    min-height: 400px;
    text-align: center;
    margin-top: 40px;
    display: inline-block;
  }
  
  
  .how h4 {
   line-height: 40px;
  }
  
  .how img {
    margin-bottom: 40px;
  }


  
.divider {
    width: 40px;
    margin: 0 auto;
    height: 2px;
    margin-bottom: 60px;
    background: var( --main-color) ;  /* fallback for old browsers */
  
  }