.auth img {

    display: block;
    margin: auto;
}

.auth form {
    margin-top: 20px;
}

.auth-btn-link {
    font-size: 12px;
    line-height: 24px;
    color: #292929;
    background-color: transparent;
    padding: 0;
    margin: 0;
}

.auth-btn-link:hover {
    color: #EF4657;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
}

.auth-btn-link:focus {
    border-color: none !important;
    box-shadow:  none !important;
}

.auth .form-check .form-check-label {
    margin: 0;
    font-size: 12px;
    line-height: 24px;

    
}

