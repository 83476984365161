.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px;
    border-radius: 4px;
    padding: 16px;
    left: 15%;
    top: 10%;
    box-sizing: border-box;
    transition: all 0.2s ease-out;
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}