.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(31, 31, 31, 0.8);
}
.Modal_Modal__WfuWT {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px;
    border-radius: 4px;
    padding: 16px;
    left: 15%;
    top: 10%;
    box-sizing: border-box;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

@media (min-width: 600px) {
    .Modal_Modal__WfuWT {
        width: 500px;
        left: calc(50% - 250px);
    }
}
.navbar-brand {
    color: #00a591 !important;
}
.MainMenu_mainmenuLink__2p7s6 {
    border: 1px solid #ced4da;
    border-radius: 4px;
    width: 100px;
    text-align: center;
}
.nav-item {
  line-height: 36px;
  /* border-bottom: 2px solid transparent; */
}

/* 
.nav-item:hover {
  border-bottom: #EF4657 solid 2px;   
} */

.nav-link {
    color: #292929 !important;
    font-size: 12px; 

}


.nav-link:hover , .nav-link:focus {
    color: #EF4657 !important;
   
}

.mainmenu-link {
  border: 1px solid #292929;
  border-radius: 4px;
}

.navbar {
    padding: 2px !important;
    background-color: white !important;
    /* min-height: 80px; */
    margin:20px 0;
}

/* .navbar-nav:first-child {
 margin-left: 40px;
 box-shadow:  9px 0px 0px -8px #d9d9d9;
} */



.gears {
  width: 350px;
  margin: 100px auto;
  display: block;
  text-align: center;
  font-weight: bold;
  font-family: Arial;
  color: #ef4657;
  text-shadow: 1px 1px 2px #ee838e
}

.gears .big {
  width: 100px;
  height: 100px;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: rotate 1.5s linear infinite;
  animation: rotate 1.5s linear infinite
}

.gears .small {
  width: 70px;
  height: 70px;
  margin-left: -6px;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: reverseRotate 1.5s linear infinite;
  animation: reverseRotate 1.5s linear infinite;
}


@-webkit-keyframes rotate {
  0% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
  100% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
}


@keyframes rotate {
  0% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
  100% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
}

@-webkit-keyframes reverseRotate {
  0% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
  100% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
}

@keyframes reverseRotate {
  0% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
  100% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
}

.auth img {

    display: block;
    margin: auto;
}

.auth form {
    margin-top: 20px;
}

.auth-btn-link {
    font-size: 12px;
    line-height: 24px;
    color: #292929;
    background-color: transparent;
    padding: 0;
    margin: 0;
}

.auth-btn-link:hover {
    color: #EF4657;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
}

.auth-btn-link:focus {
    border-color: none !important;
    box-shadow:  none !important;
}

.auth .form-check .form-check-label {
    margin: 0;
    font-size: 12px;
    line-height: 24px;

    
}


.header {
    border-bottom: 1px solid #d9d9d9;
    padding: 0 20px;
}
.gears {
  width: 350px;
  margin: 100px auto;
  display: block;
  text-align: center;
  font-weight: bold;
  font-family: Arial;
  color: #ef4657;
  text-shadow: 1px 1px 2px #ee838e
}

.gears .big {
  width: 100px;
  height: 100px;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: rotate 1.5s linear infinite;
  animation: rotate 1.5s linear infinite
}

.gears .small {
  width: 70px;
  height: 70px;
  margin-left: -6px;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: reverseRotate 1.5s linear infinite;
  animation: reverseRotate 1.5s linear infinite;
}


@-webkit-keyframes rotate {
  0% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
  100% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
}


@keyframes rotate {
  0% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
  100% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
}

@-webkit-keyframes reverseRotate {
  0% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
  100% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
}

@keyframes reverseRotate {
  0% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
  100% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
}



  :root {
    --main-color: #ef4657  ;
  }

  main {
   /* padding-top : 20px;
   text-align: center; */
   min-height: 100vh;
  
  }
  #header {
    background:   -webkit-linear-gradient(
      rgba(0, 0, 0, 0.7), 
      rgba(0, 0, 0, 0.7)
    ),url("/img/partner_bg_1.jpg");
    background:   linear-gradient(
      rgba(0, 0, 0, 0.7), 
      rgba(0, 0, 0, 0.7)
    ),url("/img/partner_bg_1.jpg");
    background-size: cover;
    /* background-position: top; */
    min-height: 500px;
    padding-top: 100px;
    padding-bottom: 100px;
       }

#header h1 {
    color: white;
}
    
  
  #rest {
    background:   -webkit-linear-gradient(
      rgba(0, 0, 0, 0.7), 
      rgba(0, 0, 0, 0.7)
    ),url("/img/partner_bg_1.jpg");
    background:   linear-gradient(
      rgba(0, 0, 0, 0.7), 
      rgba(0, 0, 0, 0.7)
    ),url("/img/partner_bg_1.jpg");
    background-size: cover;
    /* background-position: top; */
    min-height: 100vh; 
    padding-top: 100px;
    }
    
  
  .signup {
    background-color: #fcfcfc;
    border-radius: 4px;
    padding: 20px;
  }
  
  .signup h4 {
    margin-bottom: 20px;
  }
  
  .signup a {
    color: #999;
    text-decoration: none;
  }
  
  .signup a:hover {
    color: black;
  }
  
  .intro {
    color: #e0e0e0;
    text-shadow: 0 0 3px rgb(0, 0, 0), 0 0 5px rgb(0, 0, 0);
  }
  
  
  /* .intro h1 {
    padding-top: 20px;
  } */
  
  .intro h4 {
    margin-top: 45px;
    line-height: 35px;
  }


  .main {
    min-height: 80vh;
}

/*********marketing**************/

.how {
    min-height: 400px;
    text-align: center;
    margin-top: 40px;
    display: inline-block;
  }
  
  
  .how h4 {
   line-height: 40px;
  }
  
  .how img {
    margin-bottom: 40px;
  }


  
.divider {
    width: 40px;
    margin: 0 auto;
    height: 2px;
    margin-bottom: 60px;
    background: #ef4657;
    background: var( --main-color) ;  /* fallback for old browsers */
  
  }
.card {
  margin: 60px;
}
.custom-file-input:lang(en)~.custom-file-label::after {
  content: "";
}
/* The side navigation menu */


.request-card {
  max-height: 280px;
  display: block;
  overflow: auto;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  padding: 20px 0;
  margin: 20px 0;
}

.request-card h5, .request-card p {
  margin-left: 10px;
}

.locality  h5 {
  font-size: 1.2em;
}


table td {
  max-width: 400px;
} 

.Comments_starratingscss__3Hw3F {
    unicode-bidi: bidi-override;
    color: #c5c5c5;
    /* font-size: 25px; */
    height: 25px;
    /* width: 100px; */
    position: relative;
    /* text-shadow: 0px 1px 0 #a2a2a2; */
  }
  .Comments_starratingscsstop__47ItA {
    color: rgb(216, 0, 0);
    padding: 0;
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .Comments_starratingscssbottom__2F8Bv {
    padding: 0;
    display: block;
    z-index: 0;
  }

  button {
      margin:10px 0;
  }

 .Comments_comments__1-IJi:last-child hr {
    display:none;
}
.Socket_socket__17M8V {
    margin: 10px
}

button {
    min-width: 140px;
}
.Footer_footer__2sga8 {

   background: #292929;
   color: #fff;
   padding: 0 20px;
   min-height: 100px;

}

.Footer_footer__2sga8  .Footer_row__3EE8Z {
 padding: 0 20px;
}

.Footer_footerLogo__2cp0i {

display: flex;
color: #fff;
height: 66px;
font-size: 24px;
padding:  20px 0;

}

.Footer_footer__2sga8  h5 {
font-size: 0.85rem;
color: #b3b3b3;
margin-top: 10px;
text-transform: uppercase;
}

.Footer_footer__2sga8 a, .Footer_footer__2sga8 p {
color: white;
font-size: 0.85rem;
}

.Footer_footer__2sga8  a:hover {
color: #b3b3b3;
}


.Footer_footer__2sga8  li {
list-style: none;
margin: 5px 0;
}

.Footer_socialIcons__1KGwG a{
color: #fff;
margin:4px;
height: 40px;
font-size: 2rem; }


.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

