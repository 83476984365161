.navbar {
    padding: 2px !important;
    background-color: white !important;
    /* min-height: 80px; */
    margin:20px 0;
}

/* .navbar-nav:first-child {
 margin-left: 40px;
 box-shadow:  9px 0px 0px -8px #d9d9d9;
} */


