.card {
  margin: 60px;
}
.custom-file-input:lang(en)~.custom-file-label::after {
  content: "";
}
/* The side navigation menu */


.request-card {
  max-height: 280px;
  display: block;
  overflow: auto;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  padding: 20px 0;
  margin: 20px 0;
}

.request-card h5, .request-card p {
  margin-left: 10px;
}

.locality  h5 {
  font-size: 1.2em;
}


table td {
  max-width: 400px;
} 